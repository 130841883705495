require('./bootstrap');

//import $ from "jquery";

$(document).ready(function() {

    // Denotes total number of rows.
	var rowIdx = $('.child').length;

    console.log(rowIdx);
	  
	// jQuery button click event to add a row.
	$('.add-child').on('click', function () {
	   rowIdx++;
	    // Adding a row inside the tbody.
	    $('#children').append('<tr class="child"><td><input class="form-control" type="text" name="children['+(rowIdx)+'][first_name]"></td><td><input class="form-control" type="text" name="children['+(rowIdx)+'][last_name]"></td><td><input class="form-control" type="date" name="children['+(rowIdx)+'][dob]"></td><td><select class="form-select" name="children['+(rowIdx)+'][gender]" required><option>M</option><option>F</option><option>Other</option></select></td><td><select class="form-select" name="children['+(rowIdx)+'][race]"><option>W</option><option>AA</option><option>H</option><option>N/A</option><option>Other</option></select></td><td><select class="form-select" name="children['+(rowIdx)+'][ethnicity]"><option>Non-Hispanic</option><option>Hispanic</option></select></td><td><a id="child-'+(rowIdx)+'"class="btn btn-danger remove-child">X</a></td></tr>');
	}); 

	// jQuery button click event to remove a row.
    $('#children').on('click', '.remove-child', function () {
  
        // Getting all the rows next to the row
        // containing the clicked button
        var child = $(this).closest('tr').nextAll();
  
        // Iterating across all the rows 
        // obtained to change the index
        child.each(function () {
  
          // Getting <tr> id.
          var id = $(this).attr('id');
  
          // Gets the row number from <tr> id.
          var dig = parseInt(id.substring(1));
  
          // Modifying row id.
          $(this).attr('id', 'child' + dig - 1);
        });
  
        // Removing the current row.
        $(this).closest('tr').remove();
  
        // Decreasing total number of rows by 1.
        rowIdx--;
    });

});
